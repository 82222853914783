<template>
  <div class="about route__general">
    <h2 class="route__title">Hakkımızda</h2>
    <div class="about__container">
      <p>
        Emka Grup Makina, kalite politikasında aşağıda yer alan unsurları
        benimsenmektedir. Bu doğrultuda EMKA GRUP; Çalışanlarına kendilerini
        geliştirip yeni sorumluluklar almaları için fırsat tanır. Konularında
        bilgili, deneyimli, dürüst ve çalışanlarına sevgi dolu bir üst kadronun
        altında; öğrenmeye açık, çok çalışmanın erdemine inanan ve temel
        bilgileri sağlam ve vizyonlu kadroya sahiptir. Çalışanlarının firmasını
        sevmesini, yaratıcı güçlerini kullanarak geliştirmesini ve heyecanlarını
        her zaman en üst düzeyde tutmasını sağlayacak bir yönetim anlayışını
        benimser. Yönetim kadrosu; firmanın misyon ve vizyonunu özümsemiş,
        hedeflere ulaşmak için isteyerek, severek ve benimseyerek çalışan, genç,
        dinamik, özgür düşünce yapısına sahip, açık görüşlü, çağdaş iş
        ilkelerini kavramış, paylaşmanın ve takım olmanın gücüne inanmış
        kişilerden oluşur. Kurmuş olduğu Kalite Yönetim Sisteminin gereklerini
        tam olarak uygular ve etkinliğini sürekli geliştirir. Üstlendiği her işi
        yaparken, müşteri taleplerini ve yürürlükteki yasal mevzuat şartlarını
        karşılayan ürünü düzenli bir şekilde sağlar. Müşterilerine istediği
        şartları ve yasal mevzuat şartlarını sağlayan, hep aynı kalitede hizmet
        ve sunum güvencesini verir ve sistemi etkin bir şekilde uygulayarak
        müşteri memnuniyetini artırır. Kalite politikasını, şirketlerinin
        amaçlarına uygunluğunu sağlamak için sürekli gözden geçirir. Politikası
        doğrultusunda oluşturduğu hedeflerini sürekli gözden geçirerek
        geliştirir. Aldığı her işi hep aynı, en iyi nitelikte ve en gelişmiş
        teknolojik imkanları kullanarak yapar; her seferinde daha başarılı olmak
        için çalışır. Çağdaş yönetim bilişim sistemlerini kullanarak etkin bir
        kontrol ve takip olanağı yaratır.
      </p>
      <div class="about__images">
        <div class="aboutImages__top">
          <img src="../../assets/images/about__img1.png" alt="" />
        </div>
        <div class="aboutImages__bottom">
          <img src="../../assets/images/about__img2.png" alt="" />
          <img src="../../assets/images/about__img3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'Hakkımızda | Emka Radyatör',
    meta: [
      {
        name: 'description',
        content:
          'Her türlü iş makinası jeneratör, radyatör, klima, yağ soğutucu, intercooler hava kompresörü; imalat, bakım ve tamiri'
      },
      {
        name: 'keywords',
        content: 'radyatör, makina sanayi, makine, emka radyatör,'
      },
      { name: 'robots', content: 'index,follow' }
    ]
  }
};
</script>

<style></style>
